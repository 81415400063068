<!--
 * @FilePath: \zsw_new_edition\src\components\CourseRecommend.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-20 10:44:55
-->
<!--推荐课程组件-->
<template>
  <div class="recommendCourseBox">
    <div class="recommendCourseListBox">
      <ul class="recommendCourseList">
        <li
          class="recommendCourseItem"
          v-for="(item, index) in relatedInfo"
          :key="index"
          @click="to(item)"
        >
          <div class="ItemImg">
            <img :src="item.img" alt="" />
          </div>
          <div>
            <div class="ItemInfo">
              {{ item.name }}
            </div>
            <div class="ItemDesc">
              {{ item.introduce }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <a class="imgBox" :href="imgList.url">
      <img :src="imgList.src" alt="">
    </a>
  </div>
</template>

<script>
export default {
  name: "CourseRecommend",
  props: ["relatedInfo","imgList"],
  methods:{
    to(item){
      const {href} = this.$router.resolve({ path: "/teacherCourse/index",query:{id:item.id}});
      window.open(href, '_blank')
    }
  }
};
</script>

<style scoped>
.courseTitle-tag {
  background: #e94040;
  color: #ffffff;
  font-size: 0.12rem;
  padding: 0.01rem 0.05rem;
  border-radius: 0.02rem;
}
.itemStatus {
  margin-left: auto;
}
.itemTitle {
  flex: 1;
  color: #040404;
  font-size: 0.14rem;
}
.ItemInfo {
  font-size: 0.16rem;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(26, 43, 61, 1);
  height: 0.36rem;
  line-height: 0.36rem;
}
.ItemDesc {
  width:2.4rem;
  font-size: 0.12rem;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(128, 128, 128, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 0.22rem;
  line-height: 0.22rem;
  /*word-wrap: break-word;*/
  /*word-break: break-all;*/
  /*overflow: hidden;*/
  /*text-overflow:ellipsis;*/
}

.ItemInfo a {
  overflow: hidden;
}
.ItemImg {
  width: 0.64rem;
  height: 0.64rem;
  border-radius: 50%;
  margin-right: 0.1rem;
  /*margin-top:0.26rem;*/
  margin-left:0.24rem
}

.ItemImg img {
  width: 0.64rem;
  height: 0.64rem;
  border-radius: 50%;
}
.recommendCourseItem {
  width:100%;
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 0.08rem;
  height:1.16rem;
  line-height: 1.16rem;
  align-items: center;
  background: white;
  cursor: pointer;


}
.recommendCourseList {
  max-height: 8rem;
  overflow: auto;
  background: rgba(235, 236, 237, 1);
}
.recommendCourseList::-webkit-scrollbar {
  display: none;
}
.recommendCourseListBox {
  overflow: hidden;

}
.recommendCourseTitle {
  color: #333333;
  font-size: 0.16rem;
  font-weight: bold;
}
.recommendCourseBox {
  /*padding: 0.2rem;*/
}
  .imgBox{
    width:100%;
    height:1.94rem;
    display: block;
    cursor: pointer;
  }
  .imgBox img{
    width:100%;
    height:100%;
  }
</style>
