<!--课程详情页底部组件-->
<template>
  <div class="courseFloorInfo">
    <div class="floorTitle">
      <div
        class="floorTitleItem"
        :class="{ active: currentTitle == 1 }"
        @click="ChangeTitle(1)"
      >
        <p>视频详情</p>
        <div class="line"></div>
      </div>
      <div
        class="floorTitleItem"
        :class="{ active: currentTitle == 2 }"
        @click="ChangeTitle(2)"
      >
        <p>课程目录</p>
        <div class="line"></div>
      </div>
      <div
        class="floorTitleItem"
        :class="{ active: currentTitle == 3 }"
        @click="ChangeTitle(3)"
      >
        <p>学员评价</p>
        <div class="line"></div>
      </div>
    </div>
    <div class="floorDetailBox">
      <div class="floorDetail">
        <!--      详情          -->
        <div
          class="courseDetail_img"
          v-show="currentTitle == 1"
          v-html="detailInfo.content"
        ></div>
        <!--      目录          -->
        <div v-show="currentTitle == 2">
          <el-collapse v-model="activeSections" @change="handleChange">
            <el-collapse-item
              :name="index"
              v-for="(item, index) in chapterSections.list"
              :key="index"
            >
              <div slot="title">
                <i class="iconfont f16">&#xe671;</i>
                <span class="videoItemName">{{ item.title }}</span>
              </div>
              <ul class="videoChildList">
                <li style="width:100%;display: flex" v-for="(iitem, index1) in item.sections" :key="index1">
                  <div class="videoChildItem"
                       :class="{ videoChildItem_ac: videoInfo.id == iitem.id }"
                       @click="play(iitem)">
                    <div class="df_Yc">
                      <p class="freeBu mr20" v-if="iitem.is_charge == 1">免费</p>
                      <p class="freeBu1 mr20" v-else>付费</p>
                      <p class="f14 ac_c c_p">{{ iitem.title }}</p>
                    </div>
                    <div class="df_Yc">
                      <p class="f14 ac_c mr10">{{ iitem.videoTimeStr }}</p>
                      <img
                              class="videoIcon"
                              src="@/assets/image/icon/videoActive-icon.png"
                              alt=""
                      />
                    </div>
                  </div>
                  <div class="resourceBox"><div v-if="iitem.have_attachment" @click="getCanDown(iitem)"><i class="el-icon-download"></i>资料下载</div></div>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
        <!--      评价          -->
        <div v-show="currentTitle == 3">
          <div>
            学完后，有什么感想？觉得课程不错，记得好评哟！
          </div>
          <div class="pingjia1" v-if="courseStatus != 'play'">
            您需要获得学习权限才可以评价哦，<span @click="getOrder" style="cursor: pointer">立即获取</span>
          </div>
          <div class="pingjia2" v-else>
            <div class="df_Yc mt40" style="margin-bottom: 0.18rem">
              <p class="rateName">评分</p>
              <el-rate v-model="tate"> </el-rate>
            </div>
            <div class="commentBox">
              <p style="width: 50px" class="rateName">评价</p>
              <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="commentTxt"
                  maxlength="300"
                  show-word-limit
              >
              </el-input>
            </div>
            <div class="activeComment" @click="publishComment">
              发布评论
            </div>
          </div>
          <div class="pingjia2">
          </div>
          <ul class="commentList">
            <li
              class="commentItem"
              v-for="(item, index) in comments"
              :key="index"
            >
              <div class="commentAvatatBox">
                <div class="commentAvatat">
                  <img :src="item.avatar" alt="" />
                </div>
              </div>
              <div class="w708">
                <div style="display: flex;justify-content: space-between">
                  <div class="c_33 f14" style="padding-left: 0.03rem">
                    {{ item.name }}
                  </div>
                  <div class="time">{{ item.addTime }}</div>
                </div>
                <div>
                  <el-rate
                    v-model="item.score"
                    disabled
                    show-score
                    text-color="#ff9900"
                  >
                  </el-rate>
                </div>
                <div style="display: flex;justify-content: space-between">
                  <div class="c_66 f14 mt10" style="width:7.03rem">
                    {{ item.content }}
                  </div>
                  <div>
                    <div class="huifu" v-if="detailInfo.is_author && item.replay.length === 0 && showCmt !== index" @click="showCmtFun(index,null, 'add')">
                      <i class="iconfont f22 mr8">&#xe69a;</i>
                      <span class="f14">回复</span>
                    </div>
                  </div>
                </div>
                <div class="huifuInput" v-show="showCmt == index">
                  <el-input
                      type="textarea"
                      :rows="4"
                      placeholder="请输入内容"
                      v-model="textarea">
                  </el-input>
                  <div class="huifuBut" @click="submitCmt(item)">回复</div>
                </div>
                <template v-if="type !== 'edit'">
                  <div
                      class="authorReply"
                      v-for="(iitem, iindex) in item.replay"
                      :key="iindex"
                  >
                    <div>
                      {{ iitem.name }}：{{ iitem.content }}
                    </div>
                    <div v-if="detailInfo.is_author && showCmt !== index" @click="showCmtFun(index,iitem.content,'edit',iitem.id)">
                      <i class="iconfont f22 mr8">&#xe69a;</i>
                      <span class="f14">修改回复</span>
                    </div>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getClassCanDown, getClassComment, publishComment,
	checkDownTimes} from "../Api";
import {getToken} from "../utils/auth";
export default {
  name: "CourseFloor",
  props: ["chapterSections", "detailInfo", "videoInfo", "activeSections", 'courseStatus'],
  data() {
    return {
      textarea:'',
      showCmt:null,
      tate: 0,
      commentTxt: "",
      currentTitle: 1,
      value: 3.7,
      detailId: "",
      comments: {},
      page: 1,
      type: 'add',
      replayId: '',
      playSchedule: false,
    };
  },
  created() {
    this.detailId = this.$route.query.detailId;
    this.getComments();
  },
  methods: {
    //判断下载是否需要支付购买
    getCanDown(item) {
      // if (!this.isLogin) this.$login();
      //进入下载流程
      getClassCanDown({
        token: getToken(),
        pid: this.detailId,
        chapter_id: item.id,
      }).then((res) => {
        //先判断是否购买
        if (res.data.action == "appreciate") {
          let price = ''
          if(this.detailInfo.user_is_vip == 0 && this.detailInfo.is_vip == 0 && this.detailInfo.money !== '' ){
            price = Number(this.detailInfo.money).toFixed(1)
          }
          if(this.detailInfo.user_is_vip == 1 && this.detailInfo.is_vip == 0 && this.detailInfo.money !== ''){
            // console.log(this.detailInfo,(this.detailInfo.money * 0.8).toFixed(1))
            price = (this.detailInfo.money * 0.8).toFixed(1)
          }
          if(this.detailInfo.user_is_vip == 1 && this.detailInfo.is_vip == 1 && this.detailInfo.money !== '' ){
            price = Number(this.detailInfo.money).toFixed(1)
          }
          if(this.detailInfo.user_is_vip == 0 && this.detailInfo.is_vip == 1 && this.detailInfo.money !== '' ){
            price = Number(this.detailInfo.money).toFixed(1)
          }
          if(this.detailInfo.money == '' ){
            price = this.detailInfo.money
          }
          if (this.detailInfo.tab == 1 && (this.detailInfo.money == '' || this.detailInfo.money == 0)) {
            price = Number(this.detailInfo.attachment_price).toFixed(1)
          }
          this.$parent.downPayFun(price);
        } else {
          if (!this.playSchedule) {
            this.playSchedule = this.$parent.playSchedule();
          }
          //判断视频进度
          if (!this.playSchedule) {
            //提示进度不足
            this.$schedulePop().show();
          } else {
						checkDownTimes({token: getToken(), id: this.detailId, chapter_id: item.id}).then((res) => {
							if (res.data&&res.data.error == 0) {
								const downloadUrl =
											this.$ROOT_DIR +
											"/web/CloudLibraryDetail/down?id=" +
											this.detailId +
											"&chapter_id=" +
											item.id +
											"&token=" +
											getToken();
								let link = document.createElement("a");
								link.style.display = "none";
								link.href = downloadUrl;
								document.body.appendChild(link);
								link.click();
							} else if (res.data&&res.data.error == -1) {
								this.$tooltipPop().show(1, res.data.msg)
							} else if (res.data&&res.data.error == -2) {
								this.$tooltipPop().show(2, res.data.msg)
							} else {
								this.$tooltipPop().show(0, res.data.msg)
							}
						}).catch((err) => {
							console.log(err);
						});
          }
        }
      });
    },
    showCmtFun(index,content,type,replayId){
      this.showCmt = index
      this.textarea =  content
      this.type = type
      this.replayId = replayId
    },
    submitCmt(data){
      let params = {}
      if(this.type === 'add') {
        params = {
          commentId: data.commentId,
          content: this.textarea,
          pid: this.detailInfo.pid,
          token: getToken(),
          edit: 0
        }
      }
      if(this.type === 'edit') {
        params = {
          commentId:this.replayId,
          content: this.textarea,
          token: getToken(),
          edit: 1,
        }
      }
      publishComment(params).then(res=>{
        if(res.status === '200' && res.data.message === "addSuccess"){
          this.$message.success('回复成功')
          this.showCmt = null
          this.textarea=''
          this.replayId = ''
          this.type = ''
          this.getComments()
        }
      })
    },
    getOrder(){
      this.$emit('getPlay')
    },
    //  发布评论
    publishComment() {
      const data = {
        token: getToken(),
        commentId: 0,
        score: this.tate,
        content: this.commentTxt,
        order_id: 0,
        pid: this.detailInfo.pid,
      };
      publishComment(data).then((res) => {
        if (res.status === "200" && res.data.message === "addSuccess") {
          this.$message.success("评论成功");
          this.getComments()
          this.tate = 0
          this.commentTxt = ''
          // location.reload();
        }
        if(res.status === "200" && res.data.message === 'already commented'){
          this.$message.warning("已经评价过该课程");
          this.tate = 0
          this.commentTxt = ''
        }
      });
    },
    //播放其他视频
    play(data) {
      this.$parent.changeVideo(data);
      //回到顶部
      cancelAnimationFrame(this.timer);
      const self = this;
      self.timer = requestAnimationFrame(function fn() {
        const oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop - 100;
          self.timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(self.timer);
        }
      });
    },
    ChangeTitle(type) {
      this.currentTitle = type;
    },
    handleChange(val) {
      console.log(val);
    },
    getComments() {
      getClassComment({ id: this.detailId, page: this.page }).then((res) => {
        this.comments = res.data.data;
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.activeComment {
  width: 1.16rem;
  height: 0.4rem;
  line-height: 0.4rem;
  background: #f46600;
  border-radius: 0.04rem;
  color: #ffffff;
  font-size: 0.14rem;
  text-align: center;
  cursor: pointer;
  margin-top: 0.4rem;
  margin-left: auto;
}
.commentBox {
  display: flex;
  margin-top: 0.3rem;
}
.rateName:before {
  content: "*";
  color: #f56c6c;
  width: 0.02rem;
  height: 0.02rem;
  margin-right: 0.05rem;
}
.rateName {
  width: 42px;
  margin-right: 0.17rem;
  font-size: 0.14rem;
  color: #bbbbbb;
}
.w708 {
  /*width: 7.08rem;*/
  width:98%
}
.authorReply {
  margin-top: 00.2rem;
  color: #666666;
  font-size: 0.14rem;
  background: #f5f7f6;
  padding: 00.1rem;
  display: flex;
  justify-content: space-between;
}
.authorReply>div:nth-child(2){
  cursor: pointer;
  margin-left:0.3rem
}
.commentAvatat {
  width: 0.44rem;
  height: 0.44rem;
  border-radius: 50%;
  overflow: hidden;
}
.commentAvatat img {
  width: 0.44rem;
  height: 0.44rem;
  border-radius: 50%;
}
.commentAvatatBox {
  padding-top: 0.02rem;
  margin-right: 0.15rem;
}
.commentItem {
  padding: 0 0 0.3rem;
  border-bottom: 0.01rem dashed #d2dcdb;
  display: flex;
  margin-bottom: 0.3rem;
}
.videoIcon {
  cursor: pointer;
  width: 0.16rem;
  height: 00.16rem;
}
.ac_c {
  color: #f46600;
}
.freeBu {
  background: #39d578;
  color: #ffffff;
  font-size: 0.12rem;
  padding: 0.01rem 0.05rem;
}

.freeBu1 {
  background: #ff9e58;
  color: #ffffff;
  font-size: 0.12rem;
  padding: 0.01rem 0.05rem;
}
.noFreeBu {
  background: #ff9e58;
  color: #ffffff;
  font-size: 0.12rem;
  padding: 0.01rem 0.05rem;
}
.videoChildList {
  margin-top: 00.05rem;
  margin-bottom: 00.1rem;
}
.videoChildItem_ac {
  background: #fff4e0;
}
.videoChildItem {
  width: 80%;
  height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.2rem;
}
.floorDetail >>> .el-collapse-item__wrap {
  border-bottom: 0;
}
.floorDetail >>> .el-collapse-item__header {
  border-bottom: 0 !important;
  background: #f5f5f5;
  padding-left: 00.2rem;
  margin-bottom: 0.1rem;
}
.floorDetail >>> .el-collapse {
  border: 0;
}
.floorDetail >>> .el-collapse-item__content {
  padding-bottom: 0;
}
.courseDetail_img >>> img {
  max-width: 100%;
}
.videoItemName {
  color: #040404;
  font-size: 0.16rem;
  margin-left: 00.1rem;
  font-family: PingFang SC;
  font-weight: bold;
}
.floorDetail {
  height: 7.53rem;
  /* overflow: auto; */
  padding: 0.2rem;
}
.floorDetail::-webkit-scrollbar {
  display: none;
}
.floorDetailBox {
  background: #ffffff;
  width: 100%;
  /* overflow: hidden; */
  overflow-y: auto;
}
.active {
  color: #f46600;
}
.active .line {
  display: block;
}
.line {
  display: none;
  background: #f46600;
  width: 0.4rem;
  height: 0;
  border: 0.015rem solid #f46600;
  border-radius: 0.04rem;
  margin: 0.15rem auto 0;
}
.floorTitleItem {
  cursor: pointer;
  margin: 0 0.6rem;
  color: #333333;
  font-size: 0.18rem;
}
.floorTitle {
  padding-top: 0.18rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.02rem;
  background: #ffffff;
  border-radius: 0.04rem;
}

::-webkit-scrollbar {
  width: 10px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
}

.pingjia1{
  width:100%;
  text-align: center;
  margin-top: 0.8rem;
  margin-bottom:0.8rem
}
.pingjia1>span{
  color:orangered;
  text-decoration:underline;
}
.huifu{
  display: flex;
  align-items: center;
  color: #040404;
  cursor: pointer;
}
.time{
  font-size: 0.12rem;
  color:#999999;
}
.commentList{
  margin-top:0.5rem
}
.huifuBut{
  background: #F46600;
  padding: 0.05rem 0;
  border-radius: 0.04rem;
  color: #FFFFFF;
  font-size: 0.14rem;
  margin-top: 0.3rem;
  width: 0.7rem;
  text-align: center;
  margin-left: auto;
  cursor: pointer;
}
.huifuInput{
  margin-top: 0.2rem;
  width: 98%;
}
.huifu{
  display: flex;
  align-items: center;
  color: #040404;
  cursor: pointer;
}
  .resourceBox{
    margin-left:1.2rem;
    width:1.04rem;
    height:0.5rem;
    line-height: 0.5rem;
    text-align: center;
  }
  .resourceBox>div{
    margin-top:0.08rem;
    height:0.32rem;
    line-height: 0.32rem;
    text-align: center;
    color: rgba(244, 102, 0, 1);
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(244, 102, 0, 1);
    border-radius: 0.18rem;
    cursor: pointer;
  }
</style>
