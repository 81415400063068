<!--
 * @FilePath: \zsw_new_edition\src\components\MiniSearch.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2022-01-11 10:24:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-03 15:41:50
-->
<!--各页面嵌入小型搜索框-->
<template>
  <div class="miniSearchBox" :class="{selfSearchBox: searchType == '赛事'}">
    <input
			v-if="searchType == '赛事'"
      @keyup.enter="search()"
      type="text"
      v-model="key"
      placeholder="请输入搜索内容"
    />
    <input
			v-else
      @keyup.enter="search()"
      type="text"
      v-model="key"
      :placeholder="`输入关键词，查找${searchType}`"
    />
    <div
      @click="search()"
      class="dfc miniSearchButton bg1"
      v-if="searchType == '课程' || searchType == 'SU' || searchType == '3D模型'"
    >
      <img src="@/assets/image/icon/search-icon.png" alt="" />
    </div>
    <div
      @click="search()"
      class="dfc miniSearchButton bg2"
      v-if="searchType == '素材' || searchType == '图库' || searchType == 'PS' || searchType == '方案文本' || searchType == '贴图'"
    >
      <img src="@/assets/image/icon/search-icon.png" alt="" />
    </div>
    <div
      @click="search()"
      class="dfc miniSearchButton bg2"
      v-if="searchType == '赛事'"
    >
      <img src="@/assets/image/icon/search.png" alt="" />
    </div>
		
  </div>
</template>

<script>
export default {
  name: "MiniSearch",
  props: ["searchType"],
  data() {
    return {
      key: "",
    };
  },
  methods: {
    search() {
      let path = "";
      if (this.searchType == "课程") {
        path = "/course/index";
      }
      if (this.searchType == "SU") {
        path = "/su/index";
      }
      if (this.searchType == "素材") {
        path = "/material/index";
      }
      if (this.searchType == "图库") {
        path = "/photo/index";
      }
      if (this.searchType == "PS") {
        path = "/ps/index";
      }
      if (this.searchType == "3D模型") {
        path = "/max/index";
      }
      if (this.searchType == "方案文本") {
        path = "/schemeText/index";
      }
      if (this.searchType == "贴图") {
        path = "/chartlet/index";
      }
      const { href } = this.$router.resolve({
        path: path,
        query: { search: this.key },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped>
.bg1 {
  background: #f46600;
}
.bg2 {
  background: #040404;
}
.miniSearchButton {
  width: 0.8rem;
  height: 0.4rem;
  cursor: pointer;
}
.miniSearchButton img {
  width: 0.2rem;
  height: 0.2rem;
}
.miniSearchBox {
  width: 4rem;
  height: 0.4rem;
  border-radius: 0.04rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: #ffffff;
}

.selfSearchBox {
	width: 100%;
}
.miniSearchBox input {
  padding-left: 0.15rem;
  height: 0.4rem;
  flex: 1;
}
.miniSearchBox input::-webkit-input-placeholder {
  color: #bfbfbf;
  font-size: 0.12rem;
}
</style>