<!--
 * @FilePath: \zsw_new_edition\src\components\CourseVideo.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-02-09 16:31:52
-->
<!--视屏播放器组件-->
<template>
  <div class="videoBox" @contextmenu.prevent="menuPlayer()">
    <video-player
            id="myVideo"
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            @play="onPlayerPlay()"
            @pause="onPlayerPause()"
            @ready="playerReadied"
            @timeupdate="onPlayerTimeupdate($event)"
            @error="onError($event)"
    ></video-player>
  </div>
</template>

<script>
  export default {
    name: "CourseVideo",
    props: ["videoInfo", "watchRecordData", "detailInfo"],
    data() {
      return {
        watchFirst: true,
        topVal: '',
        leftVal: '',
        locationTimer: '',
        playTime: 0,
        timer: "",
        info: {},
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          autoplay: false, // 如果为true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 是否视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "video/mp4", // 类型
              //src:"https://vd4.bdstatic.com/mda-nap09jyjgrpxesum/sc/cae_h264_delogo/1642983494005133604/mda-nap09jyjgrpxesum.mp4?v_from_s=hkapp-haokan-hnb&auth_key=1643036594-0-0-22ce4fcd7752bb245cc76ab5601f83bc&bcevod_channel=searchbox_feed&pd=1&pt=3&logid=1994573856&vid=10308019604023276078&abtest=3000213_1&klogid=1994573856"
              src: require("../assets/video/2.mp4"), // url地址
            },
          ],
          poster: "", // 封面地址
          notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 当前时间和持续时间的分隔符
            durationDisplay: true, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: true, // 是否显示全屏按钮
          },
        },
        section_id: 0,
        
        ballx: 'right',
        bally: 'down',
        
        videoOnTime: new Date().getTime()
      };
    },
    mounted() {
      //监听页面刷新或关闭
      window.addEventListener("beforeunload", () => this.recordWatch());
    },
    computed: {
      player() {
        return this.$refs.videoPlayer.player;
      },
      //播放进度百分比
      schedule() {
        return (
                ((this.playTime + this.videoInfo.total_time) /
                        this.videoInfo.videoTime) *
                100 >
                10
        );
      },
    },
    methods: {
      onError(e) {
        let videoOnTime = new Date().getTime()
        if (this.videoInfo.url && (videoOnTime - this.videoOnTime) > 1000*60) {
          this.playerOptions.sources[0].src = this.videoInfo.url + '&time='+new Date().getTime();
          this.addShowInfo()
          this.videoOnTime = videoOnTime;
        }
        console.log(e);
      },
      listenDom() {
        const options = {
          childList: true,
          attributes: true,
          subtree: true,
          attributesOldValue: true,
          characterData: true,
          characterDataOldValue: true,
        }
        let target = document.getElementById('userName')
        // console.log(target)
        const reset = (expression = () => {
        }) => {
          setTimeout(() => {
            observer.disconnect();
            // 执行恢复方法
            expression();
            observer.observe(target, options);
          }, 0);
        }

        const callback = (records) => {
          const record = records[0];
          if (record.type === 'attributes' && record.attributeName === 'style') {
            reset(() => {
              let val = `position:absolute;top:${this.topVal}px;left:${this.leftVal}px;transition: 200ms !important;transition-timing-function: linear !important;color:rgb(230, 152, 77) !important;font-size:20px !important;opacity:0.4 !important; white-space: nowrap !important;visibility:visible !important`
              target.setAttribute('style', val);
            });
          } else if (record.type === 'characterData' || !record.target.innerText) {
            reset(() => {
              if (this.$store.state.user.userInfo.user_name) {
                target.innerText = 'ID:' + this.$store.state.user.uid + '(手机号' + this.$store.state.user.userInfo.user_name + ')'
              } else {
                target.innerText = 'ID:' + this.$store.state.user.uid
              }
            });
          }
        }

        const observer = new MutationObserver(callback);
        observer.observe(target, options);
      },
      menuPlayer() {
        return false;
      },
      //监听播放
      onPlayerPlay() {
        let _this = this;
        this.timer1 = setInterval(function () {
          _this.playTime++;
        }, 1000);
        //如果需要购买先进行购买
        if (!this.videoInfo.url) {
          this.$parent.payFun();
          this.player.pause();
          this.player.currentTime(0);
        }
      },
      //记录到数据库
      recordWatch() {
        if (!this.playTime) return;
        this.$parent.watchRecord(
                parseInt(this.gklog),
                this.playTime,
                this.section_id
        );
        //清空播放时长
        this.playTime = 0;
      },
      //监听暂停
      onPlayerPause() {
        this.recordWatch();
        clearInterval(this.timer1);
      },
      play() {
        if (!this.videoInfo.url) {
          this.$parent.payFun();
          this.player.pause();
          this.player.currentTime(0);
        } else {
          this.player.play();
        }
      },
      /* 获取视频播放进度 */
      onPlayerTimeupdate() {
        this.gklog = this.player.cache_.currentTime;
      },
      setCurrentTime(val) {
        this.player.currentTime(val);
      },
      //设置进度条
      playerReadied(player) {
        player.currentTime(this.watchRecordData && this.watchRecordData.watch_time_long);
      },
      randomMath(min, max) {
        return Math.round(Math.random() * (max - min) + min);
      },
      addShowInfo() {
        // 加入滚动iD显示，该功能防止录屏传播
        try{
          let _this = this
          let div = document.getElementById('myVideo')
          let div1 = div.firstChild
          let div3 = document.createElement("div");
          div3.id = 'userName';
          div3.setAttribute("class", "resize-drag");
          // 从父组件传过来的水印内容 
          // console.log(this.$store.state.user)
          if (this.$store.state.user.userInfo.user_name) {
            div3.innerText = 'ID:' + this.$store.state.user.uid + '(手机号' + this.$store.state.user.userInfo.user_name + ')'
          } else {
            div3.innerText = 'ID:' + this.$store.state.user.uid
          }
          div3.style.cssText = "position:absolute;top:30px;left:20px;transition: 200ms;transition-timing-function: linear;color:rgb(230, 152, 77);;font-size:20px;opacity:0.4 !important;display: block !important; white-space: nowrap;visibility:visible !important";
          // this.stepx = this.randomMath(1, 2)
          this.stepx = 2;
          this.stepy = 2;
          _this.locationTimer = setInterval(function () {
            _this.changeIdLocation()
          }, 200);
          div1.appendChild(div3)
          this.listenDom()
          // 可能会导致错误的代码
        } catch(error){
          return false
        }

      },
      changeIdLocation() {
        if (!this.player || !this.player.el_) {
          return
        }
        // let div = document.getElementById('myVideo')
        let div3 = document.getElementById('userName')
        if(!div3){
          clearInterval(this.locationTimer)
          this.addShowInfo()
          return
        }
        // console.log(this.player, '_________,>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>') 
        let left = parseInt(div3.style.left);
        let up = parseInt(div3.style.top);
        
        if (left <= 0) {
          this.ballx = 'right'
        } else if (left >= (this.player.el_.clientWidth - div3.offsetWidth)) {
          this.ballx = 'left'
        }
        if (up <= 0) {
          this.bally = 'down'
        } else if (up >= (this.player.el_.clientHeight - div3.offsetHeight)) {
          this.bally = 'up'
        }

        if (this.ballx == 'right') {
          left = left + this.stepx
        } else {
          left = left - this.stepy
        }
        div3.style.left = left + 'px';
        this.leftVal = left;
        
        if (this.bally == 'down') {
          up = up + this.stepx
        } else {
          up = up - this.stepy
        }
        div3.style.top = up + 'px';
        this.topVal = up;
      }
    },
    watch: {
      videoInfo: function (val) {
        //记录章节id
        this.section_id = val.id;
        //切换播放时 切换播放时长
        this.watchRecordData.watch_time_long = val.watch_time_long;
        //记录时长
        this.onPlayerPause();
        if (val.url) {
          this.videoOnTime = new Date().getTime()
          this.playerOptions.sources[0].src = val.url + '&time='+this.videoOnTime;
          // console.log(this.playerOptions.sources[0].src, '<---');
          this.addShowInfo()
        }
        this.playerOptions.poster = val.cover;
        this.setCurrentTime(this.watchRecordData && this.watchRecordData.watch_time_long);
        //延时播放 否则播放方法不生效
        let _this = this;
        if(this.watchFirst){
          this.watchFirst = false
          this.timer = setTimeout(function () {
            _this.play();
          }, 10);
        }
      },
      section_id(newValue, oldValue) {
        //记录到数据库
        if (!this.playTime) return;
        this.$parent.watchRecord(parseInt(this.gklog), this.playTime, oldValue);
        //清空播放时长
        this.playTime = 0;
      },
    },
    beforeDestroy() {
      this.recordWatch();
      clearTimeout(this.timer);
      clearInterval(this.timer1);
      clearInterval(this.locationTimer)
    },
    destroyed() {
      window.removeEventListener("beforeunload", () => this.recordWatch());
    },
  };
</script>

<style>
  .videoBox {
    margin: 0 auto;
  }
</style>
