<!--课程视右侧详情组件-->
<template>
    <div class="infoBox">
        <div class="infoHeader">
            <p class="courseInfoName">{{ detailInfo.name }}</p>
            <div class="df_Yc mt20">
                <div class="authorBox">
                    <div class="authorAvatar" @click="goLink(authorInfo.uid)">
                        <img :src="authorInfo.avatar" alt=""/>
                        <p class="authorName">{{ authorInfo.username }}</p>
                    </div>
                    <p class="authorNameDesc">
                        {{ detailInfo.attr }}
                    </p>
                </div>
            </div>
        </div>
        <div class="infoContentOuter">
            <div class="infoContent">
                <div>视频时长：{{chapterSections && chapterSections.total && chapterSections.total.videoTotalTime}}</div>
                <div>课程节数：{{chapterSections && chapterSections.total && chapterSections.total.totalSection}}节</div>
                <div>总播放量：{{detailInfo && detailInfo.view}}次</div>
                <div>上传时间：{{videoInfo.add_time | formartDate}}</div>
            </div>
        </div>
        <div class="bottomBox">
            <!--文字描述五分类-->
            <div v-if="detailInfo.user_is_vip == 0 && detailInfo.is_vip == 0 && detailInfo.money !== ''" class="toVipBox">开通课程VIP，畅享海量会员精品课程 &nbsp;&nbsp;
                <router-link :to="{ path: '/vip/course' }"><span>立即开通 >></span></router-link>
            </div>
            <div v-if="detailInfo.user_is_vip == 1 && detailInfo.is_vip == 0 && detailInfo.money !== ''" class="toVipBox" style="text-decoration:line-through">官方补贴价：{{detailInfo.money || 0}}
            </div>
            <div v-if="detailInfo.user_is_vip == 1 && detailInfo.is_vip == 1 && detailInfo.money !== ''" class="toVipBox" style="text-decoration:line-through">官方补贴价：{{detailInfo.money || 0}}
            </div>
            <div v-if="detailInfo.user_is_vip == 0 && detailInfo.is_vip == 1 && detailInfo.money !== ''" class="toVipBox">开通课程VIP，畅享海量会员精品课程 &nbsp;&nbsp;
                <router-link :to="{ path: '/vip/course' }"><span>立即开通 >></span></router-link>
            </div>
            <div v-if="detailInfo.money == ''" class="toVipBox" style="text-decoration:line-through">官方补贴价：{{detailInfo.money || 0}}
            </div>
            <!--按钮五分类-->
            <div class="buyBox" v-if="detailInfo.user_is_vip == 0 && detailInfo.is_vip == 0 && detailInfo.money !== '' && courseStatus !== 'play' ">
                <div class="buy" @click="playVideo(2)">
                    <img src="../assets/image/courseChange/left.png" alt=""/>
                    <div class="buyTop">
                        <img src="../assets/image/courseChange/start.png" alt=""/>
                        <p>官方补贴价</p>
                    </div>
                    <p class="buyBottom">
                        {{ Number(detailInfo.money).toFixed(1) || 0 }}<span>知币</span>
                    </p>
                </div>
                <router-link :to="{ path: '/vip/course' }">
                    <div class="buy">
                        <img src="../assets/image/courseChange/right.png" alt=""/>
                        <div class="buyTop1">
                            <img src="../assets/image/courseChange/start.png" alt=""/>
                            <p>VIP尊享价</p>
                        </div>
                        <p class="buyBottom">{{ (detailInfo.money*0.8).toFixed(1) || 0}}<span>知币</span></p>
                    </div>
                </router-link>
            </div>
            <div class="buyBox" v-if="detailInfo.user_is_vip == 1 && detailInfo.is_vip == 0 && detailInfo.money !== '' && courseStatus !== 'play'">
                <div class="buyFull" @click="playVideo(2)">
                    <div class="buyTop">
                        <img src="../assets/image/courseChange/start.png" alt=""/>
                        <p>VIP尊享价</p>
                    </div>
                    <p class="buyFullBottom">
                        {{ (detailInfo.money * 0.8).toFixed(1) || 0 }}<span>知币</span>
                    </p>
                </div>
            </div>
            <div class="buyBox" v-if="((detailInfo.user_is_vip == 1 && detailInfo.is_vip == 1) || courseStatus === 'play') && detailInfo.money !== ''">
                <div
                                  class="playButton2"
                                  @click="playVideo(1)"
                                >
                                  <p class="playTxt">立即观看</p></div>
            </div>
            <div class="buyBox" v-if="detailInfo.user_is_vip == 0 && detailInfo.is_vip == 1 && detailInfo.money !== '' && courseStatus !== 'play'">
                <div class="buy" @click="playVideo(2)">
                    <img src="../assets/image/courseChange/left.png" alt=""/>
                    <div class="buyTop">
                        <img src="../assets/image/courseChange/start.png" alt=""/>
                        <p>官方补贴价</p>
                    </div>
                    <p class="buyBottom">
                        {{ Number(detailInfo.money).toFixed(1) || 0 }}<span>知币</span>
                    </p>
                </div>
                <router-link :to="{ path: '/vip/course' }">
                    <div class="buy">
                        <img src="../assets/image/courseChange/right.png" alt=""/>
<!--                        <div class="buyTop1">-->
<!--                            <img src="../assets/image/courseChange/start.png" alt=""/>-->
<!--                            <p>VIP尊享价</p>-->
<!--                        </div>-->
                        <p class="buyBottomFree">VIP免费</p>
                    </div>
                </router-link>
            </div>
            <div class="buyBox" v-if="detailInfo.money == ''">
                <div
                        class="playButton2"
                        @click="playVideo(1)"
                >
                    <p class="playTxt">立即观看</p></div>
            </div>

            <div
                    class="detailInfoBottom"
                    style="margin-bottom:0.4rem"
            >
                <div class="df_Yc" @click="collect()">
                    <i class="iconfont mr10 f22 c_cc c_p" :class="{ iconColor: isCollect }"
                    >&#xe721;</i
                    >
                    <span class="f14 c_ff">收藏</span>
                </div>
                <router-link :to="{ path: '/vip/course' }">
                    <div class="df_Yc">
                        <img src="@/assets/image/icon/vip-icon.png" alt=""/>
                        <span class="f14 c_ff">VIP会员</span>
                    </div>
                </router-link>
                <a @click="pop">
                    <div class="df_Yc">
                        <img src="@/assets/image/icon/fk-icon.png" alt=""/>
                        <span class="f14 c_ff">报错</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {getToken} from "@/utils/auth";
    import {getClassCanDown, getCollectView, getClassIsCollected,checkDownTimes} from "../Api";

    export default {
        name: "CourseVideoInfo",
        props: [
            "detailInfo",
            "chapterSections",
            "authorInfo",
            "detailId",
            "bannerInfo",
            "courseStatus",
            "videoInfo",
        ],
        data() {
            return {
                currentTab: 1,
                collectList: {},
                isCollect: false,
                collectPop: {},
                playSchedule: false,
            };
        },
        created() {
            //获取课程是否收藏
            getClassIsCollected({pid: this.detailId, token: getToken()}).then(
                (res) => {
                    this.isCollect = res.data.message == "collected" ? true : false;
                }
            );
        },
        methods: {
            pop() {
                if (!this.isLogin) {
                    this.$login()
                    return false
                }
                this.$reportErrorPop().show(this.detailId)
            },
            goLink(uid) {
                this.$router.push({path: "/user/index", query: {uid: uid}});
            },
            playVideo(type = 1) {
                //如果是可播放的
                if (type == 1) {
                    this.$parent.play();
                } else if (type == 2) {
                    //需要付费
                    this.$parent.payFun();
                }
            },
            changeCollect() {
                this.isCollect = true;
            },
            changeTab(id) {
                this.currentTab = id;
            },
            //判断下载是否需要支付购买
            getCanDown() {
                if (!this.isLogin) this.$login();
                //进入下载流程
                getClassCanDown({
                    token: getToken(),
                    pid: this.detailId,
                    chapter_id: this.videoInfo.id,
                }).then((res) => {
                    //先判断是否购买
                    if (res.data.action == "appreciate") {
                        this.$parent.downPayFun(this.detailInfo.attachment_price);
                    } else {
                        if (!this.playSchedule) {
                            this.playSchedule = this.$parent.playSchedule();
                        }
                        //判断视频进度
                        if (!this.playSchedule) {
                            //提示进度不足
                            this.$schedulePop().show();
                        } else {
													checkDownTimes({token: getToken(), id: this.detailId, chapter_id: this.videoInfo.id}).then((res) => {
														if (res.data&&res.data.error == 0) {
															const downloadUrl =
																	this.$ROOT_DIR +
																	"/web/CloudLibraryDetail/down?id=" +
																	this.detailId +
																	"&chapter_id=" +
																	this.videoInfo.id +
																	"&token=" +
																	getToken();
															let link = document.createElement("a");
															link.style.display = "none";
															link.href = downloadUrl;
															document.body.appendChild(link);
															link.click();
														} else if (res.data&&res.data.error == -1) {
															this.$tooltipPop().show(1, res.data.msg)
														} else if (res.data&&res.data.error == -2) {
															this.$tooltipPop().show(2, res.data.msg)
														} else {
															this.$tooltipPop().show(0, res.data.msg)
														}
													}).catch((err) => {
														console.log(err);
													});
                        }
                    }
                });
            },
            collect() {
                //判断是否收藏过
                if (this.isCollect) {
                    this.$toast({text: "您已经收藏过了"});
                    return;
                }
                getCollectView({token: getToken(), fid: 6}).then((res) => {
                    if (res.data.error == "-1") {
                        this.$login();
                    }
                    this.collectList = res.data;
                    this.collectPop = this.$collectPop({
                        collectList: this.collectList,
                        detailInfo: this.detailInfo,
                        pid: this.detailId,
                        token: getToken(),
                        fid: 6,
                    })
                    this.collectPop.show()
                });
            },
        },
        computed: {
            ...mapGetters(["isLogin", "balance"]),
        },
        watch: {
            "collectPop.isCollect"(val) {
                this.isCollect = val
            },
        },
    };
</script>

<style scoped>
    .ewmBox {
        position: relative;
        width: 1.2rem;
        height: 1.2rem;
        background: #000;
    }

    .blur {
        -webkit-filter: blur(2px); /* Chrome, Opera */

        -moz-filter: blur(2px);

        -ms-filter: blur(2px);

        filter: blur(2px);
    }

    .kefuImg {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 0.04rem;
    }

    .playPrice {
        color: #ffffff;
        font-size: 0.22rem;
        font-weight: bold;
    }

    .playPrice span {
        font-size: 0.12rem;
    }

    .playTxt {
        margin-left: 0.1rem;
        color: #ffffff;
        font-size: 0.16rem;
        font-weight: bold;
    }

    .playTxt1 {
        color: #ffffff;
        font-size: 0.12rem;
        font-weight: bold;
    }

    .playButton {
        width: 1.23rem;
        height: 0.5rem;
        /* line-height: 0.46rem; */
        background: #f46600;
        cursor: pointer;
        border-radius: 0.04rem;
        text-align: center;
    }

    .playButton1 {
        width: 100%;
        height: 0.64rem;
        line-height: 0.64rem;
        background: #f46600;
        cursor: pointer;
        border-radius: 0.04rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .playButton2 {
        background: #00baa2;
        width: 100%;
        height:0.64rem;
        line-height: 0.64rem;;
        text-align: center;
        border-radius:0.06rem;
        letter-spacing: 0.04rem;
        cursor:pointer
    }

    .disableButton {
        background: #999999;
    }

    .fileItem {
        padding: 0.15rem 0;
    }

    .fileItem1 {
        height: 1.95rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fileItem1 p {
        color: #d9d9d9;
    }

    .fileDow {
        color: #f46600;
        font-weight: bold;
        font-size: 0.14rem;
        margin-left: auto;
        cursor: pointer;
    }

    .fileItemName p {
        font-size: 0.14rem;
        color: #ffffff;
        font-weight: bold;
        max-width: 2.9rem;
    }

    .fileList {
        padding: 0 0.2rem;
    }

    .fileIcon {
        width: 0.2rem;
        height: 0.2rem;
        margin-right: 0.1rem;
    }

    .tabItem {
        cursor: pointer;
    }

    .tabItem.active {
        color: #ffffff;
    }

    .active .active-line {
        display: block;
    }

    .active-line {
        display: none;
        width: 0.3rem;
        height: 0.03rem;
        border-radius: 0.04rem;
        background: #ffffff;
        margin: 0.1rem auto 0;
    }

    .infoChangeTab {
        height: 0.41rem;
        padding-top: 0.07rem;
        display: flex;
        justify-content: space-around;
        font-size: 0.16rem;
        color: #999999;
        box-shadow: 0px 0.01rem 0.01rem rgba(153, 153, 153, 0.15);
    }

    .headerMore {
        padding-right: 0.2rem;
        margin-top: 0.15rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 0.12rem;
        color: #d9d9d9;
    }

    .authorPrice {
        margin-left: auto;
        color: #ff9e58;
        font-size: 0.14rem;
        text-decoration: line-through;
    }

    .authorName {
        color: #d9d9d9;
        font-size: 0.14rem;
    }

    .authorNameDesc {
        color: #d9d9d9;
        font-size: 0.12rem;
        /*width: 0.24rem;*/
        font-weight: 400;
        height: 0.22rem;
        line-height: 0.22rem;
        color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(255, 255, 255, 1);
        padding: 0 0.04rem;
        border-radius: 0.08rem
    }

    .authorAvatar {
        display: flex;
        overflow: hidden;
        align-items: center;
    }

    .authorAvatar img {
        width: 0.32rem;
        height: 0.32rem;
        border-radius: 50%;
        margin-right: 0.1rem;
    }

    .courseInfoName {
        font-size: 0.2rem;
        color: #ffffff;
        font-weight: bold;
        height: 0.51rem;
        overflow : hidden;
        text-overflow: ellipsis;

        /*!* display：box主要是控制父容器里面子元素的排列方式 *!*/
        display: -webkit-box;
        /*!*  实现限制文字显示多行，多余的用... *!*/
        -webkit-line-clamp: 2;
        /*!* 属性规定框的子元素应该被水平或垂直排列。 *!*/
        -webkit-box-orient: vertical;
    }

    .infoHeader {
        /*background: #333333;*/
        width: 100%;
        padding: 0.2rem;
        /*height: 1.44rem;*/
    }

    .infoBottom {
        margin-top: auto;
        padding: 0.07rem 0.2rem;
        background: #333333;
        width: 100%;
        height: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .infoBottomBox {
        display: flex;
        width: 3.4rem;
    }

    .infoBox {
        display: flex;
        flex-direction: column;
        /*width: 4rem;*/
        height: 5.4rem;
        background: #2d2d2d;
    }

    .infoListBox {
        overflow: hidden;
        height: 2.95rem;
        position: relative;
    }

    .fileList {
        /* height: 2.95rem; */
        overflow: auto;
    }

    .fileList::-webkit-scrollbar {
        display: none;
    }

    /*.infoContent {*/
    /*  height: 3.96rem;*/
    /*}*/

    .iconColor {
        color: #ff9e58;
    }

    .authorBox {
        padding-right: 0.2rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .dfcBox {
        display: flex;
        justify-content: center;
        padding-top: 0.3rem;
    }

    .buyBox {
        display: flex;
        /* padding-top: 0.33rem; */
        justify-content: center;
        margin-bottom: 0.32rem;
        /*position: absolute;*/
        /*top: 2.23rem;*/
        /*left: 50%;*/
        /*margin-left: -1.7rem;*/
    }
    .buyFull{
        position: relative;
        width: 100%;
        height: 0.64rem;
        cursor: pointer;
        background: #f46600;
        border-radius:0.06rem
    }
    .buyFullBottom {
        width: 100%;
        position: absolute;
        bottom: 0.14rem;
        height: 0.34rem;
        font-size: 0.28rem;
        font-family: DIN;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
        text-align: center;
    }

    .buyFullBottom span {
        height: 0.22rem;
        font-size: 0.12rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
    }

    .buy {
        position: relative;
        width: 1.7rem;
        height: 0.6rem;
        cursor: pointer;
    }

    .buy img {
        width: 1.7rem;
        height: 0.6rem;
    }

    .collect {
        display: flex;
        justify-content: flex-end;
        width: 30%;
    }

    .buyTop {
        top: 5px;
        position: absolute;
        display: flex;
        padding-left: 0.1rem;
        align-items: center;
    }

    .buyTop img {
        width: 9px;
        height: 9px;
        margin-right: 6px;
    }

    .buyTop p {
        height: 0.17rem;
        font-size: 0.12rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #d9d9d9;
        opacity: 1;
    }

    .buyTop1 {
        top: 5px;
        position: absolute;
        display: flex;
        padding-left: 0.3rem;
        align-items: center;
    }

    .buyTop1 img {
        width: 9px;
        height: 9px;
        margin-right: 6px;
    }

    .buyTop1 p {
        height: 0.17rem;
        font-size: 0.12rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #d9d9d9;
        opacity: 1;
    }

    .buyBottom {
        width: 100%;
        position: absolute;
        bottom: 4px;
        height: 0.34rem;
        font-size: 0.28rem;
        font-family: DIN;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
        text-align: center;
    }
    .buyBottomFree{
        width: 100%;
        position: absolute;
        bottom: 4px;
        height: 0.34rem;
        font-size: 0.16rem;
        font-family: DIN;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
        text-align: center;
    }
    .buyBottom span {
        height: 0.17rem;
        font-size: 0.12rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
    }

    .infoContentOuter {
        width: 100%;
        display: flex;
        /*position: relative;*/
        margin-bottom: 0.38rem
    }

    .infoContent {
        margin: auto;
        width: 3.36rem;
        height: 1.8rem;
        border-radius: 0.08rem;
        background: rgba(56, 56, 56, 1);
        /*position: absolute;*/
        /*left:50%;*/
        /*transform: translateX(-50%);*/
        padding-top: 0.24rem;
        padding-left: 0.4rem;
    }

    .infoContent > div {
        height: 0.21rem;
        opacity: 1;
        /** 文本1 */
        font-size: 0.14rem;
        font-weight: 400;
        line-height: 0.21rem;
        color: rgba(229, 229, 229, 1);
        margin-bottom: 0.16rem
    }

    .bottomBox {
        /*margin:auto;*/
        width: 3.36rem;
        margin-left: 0.32rem
    }

    .bottomBox > .toVipBox {
        height: 0.22rem;
        line-height: 0.22rem;
        font-size: 0.14rem;
        font-weight: 500;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 0.25rem
    }

    .bottomBox > .toVipBox span {
        color: rgba(244, 102, 0, 1)
    }

    /*.btns {*/
    /*    display: flex;*/
    /*    justify-items: center;*/
    /*}*/

    /*.btns > div {*/
    /*    width: 33%;*/
    /*  text-align: center;*/
    /*}*/
    .detailInfoBottom {
        margin-top: 0.2rem;
        display: flex;
        justify-content: space-around;
    }

    .detailInfoBottom img {
        width: 0.18rem;
        height: 0.18rem;
        margin-right: 0.1rem;
    }
</style>
