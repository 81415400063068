<template>
  <div>
    <div class="courseDetailVideoBox">

      <div class="courseDetailHeader">
				<div class="flex-sb flex-1">
					<BreadCrumbs :breadList="breadList"></BreadCrumbs>
					<div class="mr20 redClass">配套文件在“课程目录 - 对应章节”下载</div>
				</div>
        <MiniSearch searchType="课程"></MiniSearch>
      </div>
      <div class="courseVideoInfoBox">
        <div class="courseVideoBox">
          <CourseVideo
            ref="CourseVideo"
            class="CourseVideo"
            :videoInfo="videoInfo"
            :watchRecordData="detailInfo.watchRecord"
            :detailInfo="detailInfo"
          ></CourseVideo>
        </div>
        <div class="courseVideoInfo">
          <CourseVideoInfo
            ref="CourseVideoInfo"
            :detailInfo="detailInfo"
            :chapterSections="chapterSections"
            :authorInfo="authorInfo"
            :detailId="detailId"
            :bannerInfo="bannerInfo"
            :courseStatus="courseStatus"
            :videoInfo="videoInfo"
            @payFun="payFun"
            @watchRecord="watchRecord"
          ></CourseVideoInfo>
					<div class="free" @click="freeReceive">
						<img src="../../assets/image/free.gif" alt="">
					</div>
					<div class="askBox">
						<div @click="open(1)"><img src="../../assets/image/courseChange/service.png" alt=""><div>专属客服</div></div>
						<div @click="open(2)" v-if="qun"><img src="../../assets/image/courseChange/qun.png" alt=""><div>辅导群</div></div>
					</div>
        </div>
      </div>
    </div>
    <div class="courseDetailFloorBox">
			<a :href="bannerInfo && bannerInfo[1] && bannerInfo[1].url" target="_blank" rel="noopener noreferrer">
				<img
					class="vipAd"
					v-if="bannerInfo && bannerInfo[1]"
					:src="bannerInfo && bannerInfo[1] && bannerInfo[1].src"
					alt=""
				/>
			</a>
      <div class="courseDetailFloor">
        <div class="floorLeft">
          <CourseFloor
              :courseStatus="courseStatus"
              @getPlay="playVideo"
            :detailInfo="detailInfo"
            :chapterSections="chapterSections"
            :videoInfo="videoInfo"
            :activeSections="activeSections"
          ></CourseFloor>
        </div>
        <div class="floorRight">
          <CourseRecommend :relatedInfo="relatedInfo"
                           :imgList="imgList"></CourseRecommend>
        </div>
      </div>
    </div>

    <el-dialog
            :visible.sync="serviceDialog"
            center>
      <div class="serviceRight">
        <div v-if="imgType === 1" class="wxBox">
          <img src="../../assets/image/courseChange/wx.png" alt="" style="margin-right:0.08rem">
          <div>咨询微信</div>
        </div>
        <img v-if="imgType === 1" :src="serviceImg" alt="" />
        <p style="width:100%;text-align: center;font-size: 0.12rem;margin-top:0.32rem" v-if="imgType === 1">扫码 · 添加课程顾问微信</p>

        <div v-if="imgType === 2" class="qqBox">
          <img src="../../assets/image/courseChange/qq.png" alt="">
          <div>QQ辅导群</div>
        </div>
        <img v-if="imgType === 2" :src="qun" alt="" />
        <p style="width:100%;text-align: center;font-size: 0.12rem;margin-top:0.32rem" v-if="imgType === 2">扫码 · 添加课程的辅导群</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import MiniSearch from "@/components/MiniSearch";
import CourseVideo from "@/components/CourseVideo";
import CourseVideoInfo from "@/components/CourseVideoInfo";
import CourseFloor from "@/components/CourseFloor";
import CourseRecommend from "@/components/CourseRecommend";
import {
  getBanner,
  cloudCoursePosition,
  getClassDetail,
  getVideoChapterSections,
  classTeacherList,
  getAuthorInfo,
  // getClassCanDown,
  // getInfo,
  playVideo,
  watchRecord,
} from "../../Api";
import { getToken } from "../../utils/auth";
import { mapGetters } from "vuex";
export default {
  name: "CourseDetail",
  components: {
    BreadCrumbs,
    MiniSearch,
    CourseVideo,
    CourseVideoInfo,
    CourseFloor,
    CourseRecommend,
  },
  data() {
    return {
      imgType: '',
      serviceImg: '',
      qun: '',
      serviceDialog: false,
      imgList:{},
      activeSections: [0],
      detailId: "",
      breadList: [],
      detailInfo: {},
      chapterSections: {},
      relatedInfo: {},
      authorInfo: {},
      bannerInfo: {},
      videoInfo: {},
      payObj: {
        payStatus: "noPay",
        isTopUp: false,
      },
      courseStatus: "appreciate",
    };
  },
  created() {
    this.detailId = this.$route.query.detailId;
    this.initData();
    this.detailId = this.$route.query.detailId;
    this.initData();
    // 组件加载时将右键禁用
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false')
      //禁止f12
      document.οnkeydοwn = new Function('event.returnValue=false')
    })

    // 上面的禁止f12那段代码没有生效，但是加了下面这段就能生效。
    // document.onkeydown = function (e) {
    //   if (e && e.keyCode === 123) {
    //     e.returnValue = false
    //     // e.keyCode = 0   //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错
    //     return false
    //   }
    // }
  },
  // 事件销毁前将右键开启
  beforeDestroy() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=true')
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=true')
      //禁止f12
      document.οnkeydοwn = new Function('event.returnValue=true')
    })

    // 上面的禁止f12那段代码没有生效，但是加了下面这段就能生效。
    // document.onkeydown = function (e) {
    //   if (e && e.keyCode === 123) {
    //     e.returnValue = true
    //     // e.keyCode = 0   //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错
    //     return true
    //   }
    // }
  },
  methods: {
		// 免费领知币弹窗
		freeReceive() {
			this.$picturePop().show()
		},
    open(val){
      this.imgType = val
      this.serviceDialog = true
    },
    playVideo(){
        this.$refs.CourseVideoInfo.playVideo(2)
    },
    //记录观看时长
    watchRecord(time_long, total_time, section_id) {
      watchRecord({
        token: getToken(),
        time_long: time_long,
        total_time: total_time,
        pid: this.detailInfo.pid,
        section_id: section_id,
      });
    },
    //获取视频播放进度 并判断大于10%
    playSchedule() {
      return this.$refs.CourseVideo.schedule;
    },
    // 播放
    play() {
      this.$aboutPop().show(this.goPlay);
    },
    //播放视频
    goPlay() {
      this.$refs.CourseVideo.play();
    },
    initData() {
      //获取面包屑
      cloudCoursePosition(this.detailId).then((res) => {
        this.breadList = res.data;
      });
      //获取详情
      getClassDetail({ id: this.detailId, token: getToken() }).then((res) => {
        this.detailInfo = res.data;
        this.qun = this.detailInfo.groupCode
        document.title = res.data.name + " - 课程 - 知设网";
        //获取课程章节
        getVideoChapterSections({
          pid: this.detailId,
          type: 1,
          token: getToken(),
        }).then((res) => {
          this.chapterSections = res.data;
          //循环判断之前的播放记录
          //多章节
          if (
            this.detailInfo.watchRecord.flag == "multi" &&
            this.detailInfo.watchRecord.section_id
          ) {
            res.data.list.forEach((item, index) => {
              item.sections.forEach((iitem) => {
                if (iitem.id == this.detailInfo.watchRecord.section_id) {
                  this.videoInfo = iitem;
                  this.activeSections = [index];
                }
              });
            });
          } else {
            this.videoInfo = res.data.list[0].sections[0];
            this.activeSections = [0];
          }
        });
      });
      //获取课程推荐
      classTeacherList({id:this.detailId}).then((res) => {
        this.relatedInfo = res.data;
      });
      //获取课程作者
      getAuthorInfo({ id: this.detailId }).then((res) => {
        this.authorInfo = res.data;
      });
      //获取课程banner
      getBanner({ position: 1139 }).then((res) => {
        this.bannerInfo = res.data;
        this.imgList = res.data[2];
        this.serviceImg = res.data[0].src
      });
      //判断课程是否需要购买
      playVideo({ id: this.detailId, token: getToken() }).then((res) => {
        this.courseStatus = res.data.action;
      });
    },
    //切换视频
    changeVideo(videoInfo) {
      //更新章节时长
      getVideoChapterSections({
        pid: this.detailId,
        type: 1,
        token: getToken(),
      }).then((res) => {
        this.chapterSections = res.data;
        //循环判断之前的播放记录
        this.chapterSections.list.forEach((item, index) => {
          item.sections.forEach((iitem) => {
            if (iitem.id == videoInfo.id) {
              this.videoInfo = iitem;
              this.activeSections = [index];
            }
          });
        });
      });
    },
    //购买方法
    payFun() {
      if (!this.isLogin) {
        this.$login();
      } else {
        if (this.courseStatus === "appreciate") {
          const detail = {
            name: this.detailInfo.name,
            zsStr:
              this.detailInfo.user_is_vip == 1
                ? (this.detailInfo.money * 0.8).toFixed(1)
                : this.detailInfo.money,
          };
          const data = {
            //传递给函数式组件的值（慎用哦）
            userToken: getToken(),
            detailId: this.detailId, // 项目id
            balance: this.balance, // 知币余额
            projectInfo: detail, // 项目详情
            authorUid: this.authorInfo.uid, // 作者uid
            title: "购买课程",
            titleDec: "课程名称",
          };
          this.payObj = this.$payPop({ data: data }); // 支付弹窗
          this.payObj.show();
        }
      }
    },
    downPayFun(money) {
      if (!this.isLogin) {
        this.$login();
      } else {
        const detail = {
          name: this.detailInfo.name,
          // zsStr:
          //   this.detailInfo.user_is_vip == 1
          //     ? (money * 0.8).toFixed(1)
          //     : money * 1,
          zsStr: money
        };
        const data = {
          //传递给函数式组件的值（慎用哦）
          userToken: getToken(),
          detailId: this.detailId, // 项目id
          balance: this.balance, // 知币余额
          projectInfo: detail, // 项目详情
          authorUid: this.authorInfo.uid, // 作者uid
          title: "下载配套资源",
          titleDec: "配套资源",
        };
        this.payObj = this.$payPop({ data: data }); // 支付弹窗
        this.payObj.show();
      }
    },
  },
  computed: {
    ...mapGetters(["isLogin", "balance"]),
  },
  watch: {
    "payObj.payStatus"(val) {
      // 监听支付弹窗中是否支付成功
      if (val === "paySuccess") {
        this.initData();
      }
    },
    "payObj.isTopUp"(val) {
      // 监听支付弹窗中是否支付成功
      if (val) {
        this.$topUpPop({ data: { balance: this.balance } });
      }
    },
    isLogin: function () {
      // 登录状态发生改变从新加载数据
      this.initData();
    },
  },
};
</script>

<style scoped>
.floorRight {
  width: 28%;
  border-radius: 0.04rem;
  overflow: hidden;
  background: #ffffff;
}

.floorLeft {
  width: 72%;
  margin-right: 0.2rem;
  border-radius: 0.04rem;
  overflow: hidden;
}

.courseDetailFloor {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.vipAd {
  width: 100%;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}

.courseDetailFloorBox {
  width: 100%;
  min-width: 1440px;
  background: #f7f6fb;
  padding: 0 2.8rem 0.4rem;
}

.courseVideoInfo {
  width: 4rem;
  height: 5.4rem;
	position: relative;
}

.courseVideoInfoBox {
  display: flex;
  border-radius: 0.04rem;
  /* overflow: hidden; */
  height: 5.4rem;
}

.redClass {
	color: red;
	letter-spacing: 0.015rem;
}

.courseDetailHeader {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.courseDetailVideoBox {
  height: 6.6rem;
  width: 100%;
  min-width: 1440px;
  background: #1d1d1d;
  padding: 0 2.8rem;
}

.courseVideoBox {
  flex: 1;
  background: #010001;
}
.CourseVideo {
  width: 9.6rem;
  /* height: 5.4rem; */
}
.video-js.vjs-fluid {
  height: 100%;
}
.vjs-tech {
  object-fit: cover;
}
.dark_cover {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}
.free {
	position: absolute;
	top: 0;
	right: -0.75rem;
	cursor: pointer;
}
.free img {
	width: 0.55rem;
	/* height: 1.875rem; */
}
  .askBox{
    width: 0.8rem;
    /*height:4rem;*/
    position: absolute;
		top:2rem;
		right: -0.9rem;
    text-align: center;
  }
  .askBox>div{
    /*width:100%;*/
    margin-bottom: 0.18rem;
    font-size: 0.16rem;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
  }
.serviceRight {
  /*margin: 0.22rem 0 0 1.94rem;*/
  width: 2.8rem;
  height: 3.4rem;
  opacity: 1;
  margin: auto;
  text-align: center;
}

.serviceRight img {
  width: 2.2rem;
  height: 2.2rem;
}
::v-deep .el-dialog{
  height: 4.2rem !important;
  width: 3.4rem !important;
  border-radius: 16px !important;
}
::v-deep .el-dialog__body{
  height: 4.2rem !important;
  width: 3.4rem !important;
  overflow: hidden !important;
}
  ::v-deep .el-dialog__header{
    padding: 0;
  }

  >>> .el-dialog__headerbtn .el-dialog__close{
    position: absolute;
    top:-0.14rem
  }
  .wxBox,.qqBox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.32rem
  }
  .wxBox>img,.qqBox>img{
    width:0.4rem;
    height:0.4rem;
  }
  .wxBox>div,.qqBox>div{
    font-size: 0.24rem;
    font-weight: 400;
    letter-spacing: 0.04rem;
    color: rgba(237, 87, 83, 1);
  }
</style>
