<!--面包屑组件-->
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item,index) in breadList" :key="index"><a :href="item.cate_url" target="_blank">{{ item.name }}</a></el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbs",
  props:['breadList']
}
</script>

<style scoped>

</style>